:root {
    --Title-fontSize: 1.8em;
    --Title-fontWeight: bold;
    --Title-lineHeight: 1.3;

    --Subtitle-fontSize: 1.4em;

    --Text-fontSize: 1em;

    --Button-padding: 1x 5x;
    --Button-borderRadius: .5x;
    --Button-fontSize: 1em;
    --Button-fontWeight: bold;
    --Button-backgroundColor: var(--Color-primary);
    --Button-color: var(--Color-tertiary-text);

    --Button-alternate-backgroundColor: var(--Color-alternate);
    --Button-alternate-color: var(--Color-alternate-text);

    --GhostButton-padding: 1x 2x;
    --GhostButton-borderWidth: .3x;
    --GhostButton-borderRadius: 1x;
}

.title {
    font-size: var(--Title-fontSize);
    font-weight: var(--Title-fontWeight);
    margin: 1rem * 0;
    line-height: var(--Title-lineHeight);
}

.subtitle {
    composes: title;
    font-size: var(--Subtitle-fontSize);
}

.text {
    font-size: var(--Text-fontSize);
    margin: .5rem * 1.5rem;
}

.list {
    display: block;
}

.listItem {
    position: relative;
    margin-bottom: 1em;
    list-style: none;

    &Icon {
        display: block;
        position: absolute;
        top: .2em;
        font-size: 1.2em;
        left: -1.5em;
    }
}

.button {
    font-size: var(--Button-fontSize);
    background-color: var(--Button-backgroundColor);
    color: var(--Button-color);
    padding: var(--Button-padding);
    margin: 1rem *;
    border-radius: var(--Button-borderRadius);
    box-shadow: 0 1px 5px var(--Color-primary-shadow);
    font-weight: var(--Button-fontWeight);
    transition: background-color .3s, color .3s;
    text-decoration: none;
    user-select: none;
    cursor: pointer;

    &:hover{
        background-color: var(--Color-secondary);
        color: var(--Color-secondary-text);
    }

    &:active{
        background-color: var(--Color-tertiary);
        color: var(--Color-tertiary-text);
        transition: background-color .1s, color .1s;
    }
}

.buttonAlternate {
    --Button-backgroundColor: var(--Button-alternate-backgroundColor);
    --Button-color: var(--Button-alternate-color);
}

.formButton {
    composes: button;
    border: none;
}

.ghostButton {
    composes: button;
    --Button-padding: var(--GhostButton-padding);
    border: var(--GhostButton-borderWidth) solid var(--Color-primary-text);
    border-radius: var(--GhostButton-borderRadius);
    box-shadow: none;
}

.contentBox {
    --Title-fontSize: 1.2em;
    --Text-fontSize: .9em;
    display: block;
}

.card {
    composes: contentBox;
    background-color: var(--Color-secondary-transparent);
    padding: 1x 5x;
    color: var(--Color-secondary-text);
    border-radius: 1x;
    margin: * auto;
    max-width: 60x;
}

.topic {
    composes: contentBox;
    padding: * 1x;
    margin: 2x *;

    @media (width < 740px) {
        & {
            margin: 0 *;
        }
    }
}

.scrollHint {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    font-size: 3em;
    z-index: 10;
    

    &Icon {
        transform: translateY(0);
        transition: transform .5s;
        cursor: pointer;
    }

    &Pivot {
        animation: hintFloating 1s ease infinite alternate-reverse;
    }

    &Hidden &Icon {
        transform: translateY(100%);
    }
}

@media (width < 740px) {
    :root {
        --Title-fontSize: 1.4em;
        --Text-fontSize: .9em;
        --Button-fontSize: .9em;
    }
}

@keyframes hintFloating {
    from {
        transform: translateY(0);
    }

    to {
        transform: translateY(20%);
    }
}

.scrollButtons{
    display: block;
    position: fixed;
    right: 3x;
    bottom: 1x;
    transition: transform .5s;

    &Wrapper {
        display: block;
        position: fixed;
        bottom: 0;
        right: 0;
        z-index: 20;

        @media (width < 740px) {
            &{
                display: none;
            }
        }
    }

    &Hidden {
        transform: translateX(200%);
    }
}

.scrollButton {
    composes: button;
    --Button-fontSize: 1.5em;
    --Button-padding: 1x 1.5x;
    line-height: 1;
    box-shadow: 0 1px 10px rgba(0, 0, 0, .3);
}
